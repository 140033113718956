import React from 'react';
import { useTranslation } from 'react-i18next';
import './TermsAndConditions.css';

function TermsAndConditions() {
  const { t } = useTranslation("Login");

  return (
    <div className="terms-container">
      <h1>{t("terms_general_title")}</h1>
      <p>{t("terms_general_body")}</p>
      <h2>{t("terms_website_title")}: 
  <a href="https://www.six-keys.pro" target="_blank" rel="noopener noreferrer" className="custom-link">
    www.six-keys.pro
  </a>
</h2>
      <p>{t("terms_website_body")}</p>
      <h2>{t("terms_acceptance_title")}</h2>
      <p>{t("terms_acceptance_body")}</p>
      <h2>{t("terms_usage_title")}</h2>
      <p>{t("terms_usage_body")}</p>
      <h2>{t("terms_prohibitions_title")}</h2>
      <p>{t("terms_prohibitions_body")}</p>
      <h2>{t("terms_privacy_title")}</h2>
      <p>{t("terms_privacy_body")}</p>
      <h2>{t("terms_data_title")}</h2>
      <p>{t("terms_data_body")}</p>
      <h2>{t("terms_disclosure_title")}</h2>
      <p>{t("terms_disclosure_body")}</p>
      <h2>{t("terms_ip_title")}</h2>
      <p>{t("terms_ip_body")}</p>
      <h2>{t("terms_jurisdiction_title")}</h2>
      <p>{t("terms_jurisdiction_body")}</p>
    </div>
  );
}

export default TermsAndConditions;
