import React from 'react'
import "./Loader.css"

function Loader() {
  return (
<div className="custom-loader"></div>
  )
}

export default Loader;
