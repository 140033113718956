import React from "react";
import "./Maintenance.css";
import { useTranslation } from "react-i18next";

const Maintenance = () => {
  const { t } = useTranslation("Maintenance"); // Namespace for translations

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h1>{t("MaintenanceTitle")}</h1>
        <p>{t("MaintenanceMessage")}</p>
        <a href="https://six-keys.pro/" className="refresh-link">
          {t("RefreshPage")}
        </a>
      </div>
    </div>
  );
};

export default Maintenance;
