import React from "react";
import "./Unauthorized.css";
import { useTranslation } from "react-i18next";

const Unauthorized = () => {
  const { t } = useTranslation("Unauthorized"); // Namespace for translations

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h1>{t("SessionExpired")}</h1>
        <p>{t("SessionExpiredMessage")}</p>
        <a href="https://six-keys.pro" className="login-link">
          {t("GoToLogin")}
        </a>
      </div>
    </div>
  );
};

export default Unauthorized;
