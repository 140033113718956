import React, { Fragment, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './LoginSignUp.css';
import { useTranslation } from "react-i18next";

function LoginSignUp() {
  const [activeTab, setActiveTab] = useState(0);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [businessField, setBusinessField] = useState('');
  const [establishmentDate, setEstablishmentDate] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [error, setError] = useState('');
  const [isSecondStep, setIsSecondStep] = useState(false);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [organizationCode, setOrganizationCode] = useState("");
  const [generatedCode, setGeneratedCode] = useState(""); // To show the code for admins
  const [role, setRole] = useState('employee'); // Default to employee
  // const dispatch = useDispatch()
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  

  const { t } = useTranslation("Login");

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setOrganizationName('');
    setOrganizationCode('');
  };

  // Utility function to dynamically update the base URL
  const updateBaseTag = (baseURL) => {
    const baseTag = document.querySelector("base") || document.createElement("base");
    baseTag.href = baseURL;
    if (!document.head.contains(baseTag)) {
      document.head.appendChild(baseTag);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: fullName, password }),
        credentials: "include", // Include cookies
      });

      const data = await response.json();
      
      if (response.ok) {
        updateBaseTag(`${process.env.REACT_APP_BACKEND_BASE_URL}/frontend`);
        navigate("/frontend/");
      }  else {
        const translatedError = t(data.msgKey || "genericError");
        setError(translatedError);
      }
    } catch (error) {
      setError(t("Login failed. Please try again."));
      console.error("Error during login:", error);
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!isSecondStep) {
      setIsSecondStep(true);
      return;
    }

    try {
      const payload = {
        username: fullName,
        email,
        password,
        businessField,
        establishmentDate,
        companySize,
        role,
        organizationName: role === 'admin' ? organizationName : undefined,
        organizationCode: role === 'employee' ? organizationCode : undefined,
      };

      console.log("Submitting registration form:", payload);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      const data = await response.json();
      console.log("Server response:", response.status, data);

      if (response.ok) {
        if (role === 'admin') {
          setGeneratedCode(data.code);
        }
        setShowPopup(true); // Show popup
      } else {
        const translatedError = t(data.msgKey || "registration.genericError");
        setError(translatedError);
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setError(t("registration.genericError"));
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setActiveTab(1); // Rediriger vers l'onglet de connexion
  };


  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: resetEmail }),
      });

      if (response.ok) {
        setError(t("Password reset email sent. Please check your inbox."));
      } else {
        const data = await response.json();
        setError(data.msg || t("Failed to send password reset email."));
      }
    } catch (err) {
      console.error("Forgot Password error:", err);
      setError(t("An error occurred. Please try again later."));
    }
  };


  

  return (
    <div className="entire">
      {/* Message d'erreur ou d'avertissement */}
      {error && (
        <div className="form-message">
          {error}
        </div>
      )}

      <div className="text-and-login">
        <div className="logo-section">
          <img src="/Pictures/logo_transparent.png" alt="Six Keys Logo" className="login-logo" />
          <p className="tagline">
            {t("Leading Businesses to")} <strong>{t("Magical")}</strong> {t("Growth")}
          </p>
        </div>

        <div className="login-container">
          <div className="content-box">
            <div className="tabs">
              <button className={`tab ${activeTab === 0 ? 'active-tab' : ''}`} onClick={() => handleTabChange(0)}>
                {t("Sign Up")}
              </button>
              <button className={`tab ${activeTab === 1 ? 'active-tab' : ''}`} onClick={() => handleTabChange(1)}>
                {t("Login")}
              </button>
            </div>

            {forgotPassword ? (
              <form onSubmit={handleForgotPassword} className="form-box">
                <input
                  type="email"
                  required
                  placeholder={t("Enter your email")}
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  className="input_field_login"
                />
                <button type="submit" className="submit-button">{t("Send Reset Link")}</button>
                <button type="button" className="back-button" onClick={() => setForgotPassword(false)}>
                  {t("Back to Login")}
                </button>
                {error && <p className="error-text">{error}</p>}
              </form>
            ) : activeTab === 0 ? (
              <form onSubmit={handleSignUpSubmit} className="form-box">
                {!isSecondStep ? (
                  <Fragment>
                    <input
                      type="text"
                      required
                      placeholder={t("Full Name")}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      className="input_field_login"
                    />
                    <input
                      type="email"
                      required
                      placeholder={t("Email")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="input_field_login"
                    />
                    <input
                      type="password"
                      required
                      placeholder={t("Password")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="input_field_login"
                    />
                    <select required value={role} onChange={handleRoleChange} className="input_field_select">
                      <option value="employee">{t("Employee")}</option>
                      <option value="admin">{t("Admin")}</option>
                    </select>
                    {error && <p className="error-text">{error}</p>}
                    <button type="submit" className="submit-button">{t("Continue")}</button>
                  </Fragment>
                ) : (
                  <Fragment>
                    <input
                      type="text"
                      required
                      placeholder={t("Business Field")}
                      value={businessField}
                      onChange={(e) => setBusinessField(e.target.value)}
                      className="input_field_login"
                    />
                    <input
                      type="date"
                      required
                      placeholder={t("Business Establishment Date")}
                      value={establishmentDate}
                      onChange={(e) => setEstablishmentDate(e.target.value)}
                      className="input_field_login_date"
                      id="input_field_login_date"
                    />
                    <select
                      required
                      value={companySize}
                      onChange={(e) => setCompanySize(e.target.value)}
                      className="input_field_select"
                    >
                      <option value="">{t("How large is your company?")}</option>
                      <option value="Just me">{t("Just me")}</option>
                      <option value="1-5">1-5</option>
                      <option value="5-25">5-25</option>
                      <option value="25-100">25-100</option>
                      <option value="250-1000">250-1000</option>
                      <option value="1000+">1000+</option>
                      <option value="Prefer not to share">{t("Prefer not to share")}</option>
                    </select>

                    {role === 'admin' ? (
                      <Fragment>
                        <input
                          type="text"
                          required
                          placeholder={t("Organization Name")}
                          value={organizationName}
                          onChange={(e) => setOrganizationName(e.target.value)}
                          className="input_field_login"
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <input
                          type="text"
                          required
                          placeholder={t("Organization Code")}
                          value={organizationCode}
                          onChange={(e) => setOrganizationCode(e.target.value)}
                          className="input_field_login"
                        />
                      </Fragment>
                    )}

                    <div className="checkbox-section">
                      <input type="checkbox" id="terms" required />
                      <label htmlFor="terms">
                        {t("By proceeding, you agree to the")}{" "}
                        <Link to="/terms">{t("Terms and Conditions")} {t("and")} {t("Privacy Policy")}</Link>
                      </label>
                    </div>

                    <div className="button-group">
                      <button type="button" className="back-button" onClick={() => setIsSecondStep(false)}>
                        {t("Back")}
                      </button>
                      <button type="submit" className="submit-button">{t("Sign Up")}</button>
                    </div>
                  </Fragment>
                )}
              </form>
            ) : (
              <form onSubmit={handleLoginSubmit} className="form-box">
                <input
                  type="text"
                  required
                  placeholder={t("Username")}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="input_field_login"
                />
                <input
                  type="password"
                  required
                  placeholder={t("Password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input_field_login"
                />
                {error && <p className="error-text">{error}</p>}
                <button type="submit" className="submit-button">{t("Login")}</button>
                <button
                  type="button"
                  className="forgot-password-button"
                  onClick={() => setForgotPassword(true)}
                >
                  {t("Forgot Password?")}
                </button>
              </form>
            )}
          </div>

          <div className="side-title">
            <h4 className="h4_login">{t("We help businesses grow faster and better")}</h4>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>{t("Registration Successful")}</h3>
            {role === 'admin' && (
              <div>
                <p>{t("Your organization code is:")}</p>
                <div className="copy-code-container">
                  <strong>{generatedCode}</strong>
                  <button
                    className="copy-button"
                    onClick={() => navigator.clipboard.writeText(generatedCode)}
                    title={t("Copy to clipboard")}
                  >
                    📋
                  </button>
                </div>
              </div>
            )}
            <p>{t("Please check your email to verify your account, then log in.")}</p>
            <button className="close-popup-button" onClick={closePopup}>{t("Go to Login")}</button>
          </div>
        </div>
      )}
    </div>
  );
}


export default LoginSignUp;
