import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import './ResetPassword.css';

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const token = searchParams.get('token');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, newPassword }),
      });

      const data = await response.json();
      if (response.ok) {
        setSuccess("Password reset successfully.");
      } else {
        setError(data.msg || "Password reset failed.");
      }
    } catch (err) {
      console.error("Reset Password error:", err.message);
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="reset-password-container">
    <form className="reset-password-form" onSubmit={handleResetPassword}>
      <input
        className="reset-password-input"
        type="password"
        required
        placeholder="Enter new password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <button className="reset-password-button" type="submit">Reset Password</button>
      {error && <p className="reset-password-error">{error}</p>}
      {success && <p className="reset-password-success">{success}</p>}
    </form>
    </div>
  );
}

export default ResetPassword;
